import { useEffect, useState } from 'react';
import NextImage from 'next/image';

import { FB_APP_ID } from '../lib/config';

const facebook = '/images/sharing/facebook.png';
const twitter = '/images/sharing/twitter.png';
// const whatsapp = '/images/sharing/whatsapp.png';
const linkedin = '/images/sharing/linkedin.png';
const email = '/images/sharing/email.png';

export function SocialIcons() {
  const [url, setUrl] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    setUrl(window.location.href);
    setTitle(document.title);
  }, [url]);

  return (
    <div className="d-flex align-items-center justify-content-center my-5 gap-3">
      {FB_APP_ID && (
        <a
          href={`https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${url}&redirect_uri=${url}`}
          rel="noopener noreferrer"
          target="_blank">
          <NextImage
            alt="Share article on facebook"
            className="img-fluid"
            src={facebook}
            width={50}
            height={50}
          />
        </a>
      )}
      <a
        href={`https://twitter.com/intent/tweet?text=${title || ''}&url=${url}`}
        rel="noopener noreferrer"
        target="_blank">
        <NextImage
          alt="Share article on twitter"
          className="img-fluid"
          src={twitter}
          width={50}
          height={50}
        />
      </a>
      {/* <a
        href={`https://wa.me/?text=${title || ''} ${url}`}
        rel="noopener noreferrer"
        target="_blank">
        <NextImage
          alt="Share article on whatsapp"
          className="img-fluid"
          src={whatsapp}
          width={50}
          height={50}
        />
      </a> */}
      <a
        href={`https://www.linkedin.com/shareArticle?url=${url}`}
        rel="noopener noreferrer"
        target="_blank">
        <NextImage
          alt="Share article on linkedin"
          className="img-fluid"
          src={linkedin}
          width={50}
          height={50}
        />
      </a>
      <a
        href={`mailto:?subject=${title}&body=${url}`}
        rel="noopener noreferrer"
        target="_blank">
        <NextImage
          alt="Share article by email"
          className="img-fluid"
          src={email}
          width={50}
          height={50}
        />
      </a>
    </div>
  );
}
