import { Fragment, useState } from 'react';
import { PrismicLink } from '@prismicio/react';
import NextImage from 'next/image';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import { linkResolver } from '../prismicio';
import { Bounded } from './Bounded';
import Caret from './Caret';

const FlagIcon = ({ lang }) => {
  const code = lang.substring(3).toLowerCase();

  return <span className={`fi fi-${code}`} />;
};

export const Header = ({ alternateLanguages = [], items = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Bounded as="header" yPadding="xs">
      <Navbar expand="lg">
        <PrismicLink href="/" className="navbar-brand">
          <NextImage
            src="/images/questionmark-logo.svg"
            alt="Questionmark Logo"
            width={256}
            height={38}
          />
        </PrismicLink>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="ms-auto">
            {items.map((item) => (
              <Fragment key={item.primary.label}>
                {item.items.length > 0 && item.primary.label && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav className="me-3">
                      {item.primary.label} <Caret />
                    </DropdownToggle>
                    <DropdownMenu tag="ul">
                      {item.items.map((x) => (
                        <li key={x.label}>
                          <PrismicLink field={x.link} className="dropdown-item">
                            {x.label}
                          </PrismicLink>
                        </li>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {item.items.length === 0 && item.primary.label && (
                  <li className="nav-item me-3">
                    <PrismicLink
                      field={item.primary.link}
                      className={`nav-link nav-link-${item.primary.label.toLowerCase()}`}>
                      {item.primary.label}
                    </PrismicLink>
                  </li>
                )}
              </Fragment>
            ))}

            {alternateLanguages.map((lang) => (
              <li key={lang.lang} className="nav-item">
                <PrismicLink
                  href={linkResolver(lang)}
                  // No language in the URLs, we have different uids.
                  // locale={lang.lang}
                  className="nav-link">
                  <FlagIcon lang={lang.lang} />
                </PrismicLink>
              </li>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
    </Bounded>
  );
};
