import { PrismicLink } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import NextImage from 'next/image';
import Link from 'next/link';
import { Bounded } from './Bounded';
import Subscribe from './Subscribe';

const SOCIAL = {
  twitter: 'https://twitter.com/Questionmark_NL',
  facebook: 'https://facebook.com/questionmarkfoundation',
  linkedin: 'https://www.linkedin.com/company/foundation-questionmark'
};

export const Footer = ({ settings, items = [] }) => {
  const year = new Date().getFullYear();
  const [nav1, nav2, nav3, nav4] = items;
  return (
    <Bounded as="footer" className="border-bottom-0">
      <div className="py-5">
        <div className="row">
          <div className="col-lg-3">
            <div className="d-flex flex-column gap-4 mb-5">
              <NextImage
                src="/images/social/qm.svg"
                alt="Questionmark"
                width={60}
                height={59}
              />
              <NextImage
                src="/images/social/anbi.svg"
                alt="ANBI"
                width={90}
                height={72}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <FooterNavGroup nav={nav1} />
            <FooterNavGroup nav={nav3} />
          </div>
          <div className="col-lg-2">
            <FooterNavGroup nav={nav2} />
          </div>
          <div className="col-lg-4">
            <div className="d-flex gap-2 align-items-center">
              {Object.keys(SOCIAL).map((key) => (
                <Link href={SOCIAL[key]} target="_blank" key={key}>
                  <NextImage
                    src={`/images/social/${key}.svg`}
                    alt={key}
                    width={30}
                    height={30}
                  />
                </Link>
              ))}
            </div>
            <br />
            <br />
            <div className="mb-2">
              <strong>Newsletter</strong>
            </div>
            <Subscribe id={settings.data.newsletterId} />
            <br />
            <FooterNavGroup nav={nav4} />
          </div>
        </div>

        <div className="text-center small text-muted pt-4">
          Copyright &copy; {year} {prismicH.asText(settings.data.siteTitle)}
        </div>
      </div>
    </Bounded>
  );
};

function FooterNavGroup({ nav }) {
  if (!nav) return null;
  if (nav && !nav.items.length) return null;
  return (
    <div className="mb-5">
      <strong>{nav.primary.label}</strong>
      <ul className="list-unstyled">
        {nav.items.map((item) => (
          <li key={item.label} className="mt-1">
            <PrismicLink field={item.link} className="text-ship-light">
              {item.label}
            </PrismicLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
