import { Header } from './Header';
import { Footer } from './Footer';
import CTA from './CTA';

export const Layout = ({
  alternateLanguages,
  navigation,
  settings,
  page,
  cta = true,
  children
}) => {
  // type = 'navigation' for header nav items
  // type = 'navigation_footer for footer nav items
  const getNav = (type) => {
    return Object.keys(navigation.data)
      .reduce((arr, x) => arr.concat(navigation.data[x]), [])
      .filter((x) => x.slice_type === type);
  };

  const headerNav = getNav('navigation');
  const footerNav = getNav('navigation_footer');

  return (
    <>
      <Header alternateLanguages={alternateLanguages} items={headerNav} />
      <main>
        {children}
        {cta && <CTA {...page.data} />}
      </main>
      <Footer settings={settings} items={footerNav} />
    </>
  );
};
