import * as prismicH from '@prismicio/helpers';
import clsx from 'clsx';
import { PrismicRichText, PrismicLink } from '@prismicio/react';
import Subscribe from './Subscribe';
import { Heading } from './Heading';
import { SocialIcons } from './SocialIcons';

export default function CTA({
  ctaDescription,
  ctaButtonText,
  ctaButtonLink,
  showSocialIcons,
  mailchimpAudienceId,
  backgroundColour
}) {
  const hasCTAButton =
    prismicH.isFilled.keyText(ctaButtonText) &&
    prismicH.isFilled.link(ctaButtonLink);
  const hasCTADesc = prismicH.isFilled.richText(ctaDescription);

  if (
    !hasCTADesc &&
    (!hasCTAButton || !mailchimpAudienceId || !showSocialIcons)
  )
    return null;

  return (
    <div
      className={clsx(
        'py-6 border-bottom',
        backgroundColour && `text-bg-${backgroundColour}`
      )}>
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-8 mx-auto text-center">
            <PrismicRichText
              field={ctaDescription}
              components={{
                heading2: ({ children }) => (
                  <Heading as="h2" className="mb-2">
                    {children}
                  </Heading>
                )
              }}
            />
            <div className="mt-4">
              {mailchimpAudienceId && (
                <div className="d-flex justify-content-center mb-4">
                  <Subscribe id={mailchimpAudienceId} />
                </div>
              )}
              {prismicH.isFilled.link(ctaButtonLink) &&
                prismicH.isFilled.keyText(ctaButtonText) && (
                  <PrismicLink
                    field={ctaButtonLink}
                    className="btn btn-primary px-4">
                    {ctaButtonText}
                  </PrismicLink>
                )}
              {showSocialIcons && <SocialIcons />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
