import { useState } from 'react';
import { Form, Input, Button, Row, Col } from 'reactstrap';
import { useContext } from 'react';
import { LocaleContext } from './LocaleContext';
import mailchimp from '../lib/mailchimp';

function Subscribe({ id }) {
  const locale = useContext(LocaleContext);

  const [subscribing, setSubscribing] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const isValidEmail = validate(email);

  const subscribe = (e) => {
    e.preventDefault();
    setSubscribing(true);
    mailchimp(email, id, locale)
      .then(() => {
        setSubscribing(false);
        setSubscribed(true);
      })
      .catch(() => {
        setSubscribing(false);
      });
  };

  return (
    <div className="mailchimp-subscribe">
      {!subscribed && (
        <Form className="mx-auto" onSubmit={subscribe}>
          <Row className="row-cols-lg-auto g-3 align-items-center">
            <Col xs={8}>
              <Input
                type="email"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Col>
            <Col xs={4}>
              <Button color="primary" disabled={subscribing || !isValidEmail}>
                {subscribing && '...'} Subscribe
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {subscribed && (
        <div>
          <div className="text-large">Thank you!</div>
          {/* <Share /> */}
          {/* <Adwords /> */}
        </div>
      )}
    </div>
  );
}

export default Subscribe;

function validate(email) {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );
}
