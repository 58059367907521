export default function Caret() {
  return (
    <svg
      className="nq1wg6-4 DDGDT"
      width="9"
      height="6"
      viewBox="0 0 9 6"
      xmlns="http://www.w3.org/2000/svg">
      <title>chevron</title>
      <path
        d="M1 1l3.523 3.523L8 1.046"
        strokeWidth="1.5"
        stroke="#000"
        fill="none"
      />
    </svg>
  );
}
