import Head from 'next/head';
import * as prismicH from '@prismicio/helpers';
import { linkResolver } from '../prismicio';
import { isProdRuntime } from '../lib/config';

export default function Meta({ page, settings, meta }) {
  const metaTitle = meta?.title || page?.data?.metaTitle;
  const metaDescription = meta?.description || page?.data?.metaDescription;
  const metaImage = meta?.image || page?.data?.metaImage;

  return (
    <Head>
      <title>
        {`${
          metaTitle || prismicH.asText(page?.data?.title)
        } | ${prismicH.asText(settings.data.siteTitle)}`}
      </title>
      {metaDescription && <meta name="description" content={metaDescription} />}

      {(!isProdRuntime || page?.tags?.includes('hidden')) && (
        <meta name="robots" content="noindex, nofollow, noarchive" />
      )}

      {page?.alternate_languages?.map((lang) => (
        <link
          rel="alternate"
          hreflang={lang.lang}
          href={linkResolver(lang)}
          key={lang.lang}
        />
      ))}

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Questionmark" />
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}
      {metaImage?.url && <meta property="og:image" content={metaImage.url} />}
    </Head>
  );
}
